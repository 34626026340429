/**
 * Formats a given amount in cents to a currency string.
 *
 * @param cents - The amount in cents to format. Defaults to 0.
 * @param currency - The currency code to use for formatting. Defaults to 'CHF'.
 * @param negative - Indicates if the amount should be formatted as negative. Defaults to false.
 * @returns The formatted currency string.
 */
export function formatCents(cents: number = 0, currency: string = 'CHF', negative: boolean = false): string {
    // Use 'de-CH' as default number formatter, except for English
    const numberFormatLocale = useNuxtApp().$i18n.locale.value === 'en' ? 'en' : 'de-CH';

    let formattedPrice = Intl.NumberFormat(numberFormatLocale, {
        style: 'currency',
        currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(cents * (negative ? -1 : 1) / 100);

    // Add space between currency and minus sign if necessary
    if (negative && formattedPrice.includes('-')) {
        formattedPrice = formattedPrice.replace(/(\D)-(\d)/, '$1 -$2');
    }

    return formattedPrice;
}

/**
 * Formats a given price object to a currency string.
 *
 * @param price - The price object containing the amount in cents and the currency code.
 * @param negative - Indicates if the amount should be formatted as negative. Defaults to false.
 * @returns The formatted currency string.
 */
export function formatPrice(price: Price | undefined, negative: boolean = false): string {
    return formatCents(price?.amount, price?.currency, negative);
}

/**
 * Returns the price as an absolute value (positive).
 *
 * @param price - The price object containing the amount in cents and the currency code.
 * @returns Positive price
 */
export function absPrice(price: Price | undefined): Price {
    return price ? { ...price, amount: Math.abs(price?.amount ?? 0) } : { amount: 0, currency: 'CHF' };
}
